<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <b-card title="Box Express 🚀">
      <b-card-text>Statistique de livraison</b-card-text>
    </b-card>
    <div
      v-if="isLoading === true"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <b-container fluid>
      <b-badge
        v-if="isFreeTrial"
        variant="warning"
      >
        Essai gratuit! Déverrouillez toutes les fonctionnalités en mettant à niveau votre plan.
      </b-badge>
      <h2>
        Paiements Hebdomadaires pour les Dépôts Actifs
      </h2>
      <b-row>
        <b-col
          v-for="(repositoryData, repositoryName) in weeklyPaymentsDetails"
          :key="repositoryName"
          lg="6"
          class="mb-4"
        >
          <b-card class="h-100">
            <template #header>
              <h5>{{ repositoryName }}</h5>
            </template>
            <b-list-group v-if="repositoryData.last_week">
              <b-list-group-item
                v-for="(details, date) in repositoryData.last_week"
                :key="date"
              >
                <div class="d-flex justify-content-between">
                  <span><b>{{ formatDate(date) }} :</b></span>
                  <span>
                    Total Prix TTC : {{ details.total_price_ttc_per_day }} TND,
                    Nombre de Paiements : {{ details.total_number_of_payments_per_day }}
                  </span>
                </div>
              </b-list-group-item>
            </b-list-group>
            <b-card-text class="mt-2">
              <strong>Total pour la semaine :</strong>
              <span>{{ repositoryData.total_price_ttc_week }} TND</span>
              <span>, Total Nombre de Paiements : {{ repositoryData.total_number_of_payments_per_week }}</span>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <b-card
        v-if="totalPriceTTCForAllRepositories"
        class="mt-3"
      >
        <b-card-text>
          Total Prix TTC pour Tous les Dépôts : {{ totalPriceTTCForAllRepositories }} TND,
          Total Nombre de Paiements pour Tous les Dépôts : {{ totalNumberOfPaymentsForAllRepositories }}
        </b-card-text>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import {
  BCard, BCardText,
  BSpinner, BCol, BRow,

} from 'bootstrap-vue'

import axios from 'axios'
import storeAuth from '@/store/store'

export default {
  name: 'Dashboard',
  components: {
    BCard,
    BCardText,
    BSpinner,
    BCol,
    BRow,
  },
  data() {
    return {
      isLoading: false,
      user: {},
      weeklyPaymentsDetails: {},
      totalPriceTTCForAllRepositories: null,
      totalNumberOfPaymentsForAllRepositories: null,
      isFreeTrial: true,
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
    }
  },
  created() {
    this.user = storeAuth.state.user
    if (this.user.role === 'admin') {
      this.fetchWeeklyPayments()
    }
  },
  methods: {
    async fetchWeeklyPayments() {
      try {
        this.isLoading = true
        const response = await axios.get('/api/kpi/weekly-payments/')
        this.weeklyPaymentsDetails = response.data.weekly_payments_details || {}
        this.totalPriceTTCForAllRepositories = response.data.total_price_ttc_for_all_repositories || null
        this.totalNumberOfPaymentsForAllRepositories = response.data.total_number_of_payments_for_all_repositories || null
        this.isLoading = false
      } catch (error) {
        console.error('Error fetching weekly payments:', error)
      }
    },
    formatDate(date) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      return new Date(date).toLocaleDateString('fr-FR', options)
    },
    show(level) {
      this.$router.push({ name: 'order-list', params: { level } })
    },
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(2, '0')} | ${h}`
    },
  },
}
</script>
  <style lang="scss" scoped>
  @import '@core/scss/vue/libs/vue-good-table.scss';

  .b-card {
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
    margin-bottom: 1rem;
    background-color: #fff;
    padding: 1rem;
  }

  .b-badge {
    font-size: 14px;
  }
  </style>
